import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Syllables = () => {
  return (
    <div>
      <Layout>
        <Head title="Syllables" />
        <h2>Syllables</h2>
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/1.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/2.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/3.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/4.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/5.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/6.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/7.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/8.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/9.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/10.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/11.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565822648/color%20sounds/12.jpg"
          alt="Benji Friedman digital combinatorial permutation language art"
        />
        <Link to="/media">
          <h3>Media</h3>
        </Link>
      </Layout>
    </div>
  );
};

export default Syllables;
